<template>
    <div>
        <b-container>
            <b-row>
                <b-col sm="12">
                    <b-form @submit.prevent="ekle" class="text-left mt-5">
                        <b-form-group
                            id="input-group-1"
                            label="Konteyner Id:"
                            label-for="input-1">
                            <b-form-input
                            id="input-1"
                            v-model="form.atik_alim_konteyner"
                            type="number"
                            required
                            placeholder="Konteyner Id girin"
                            ></b-form-input>
                        </b-form-group>

                        <b-form-group
                            id="input-group-2"
                            label="Toplanan Miktar:"
                            label-for="input-2">
                            <b-form-input
                            id="input-2"
                            v-model="form.atik_alim_miktar"
                            type="number"
                            required
                            placeholder="Toplanan miktar girin"
                            ></b-form-input>
                        </b-form-group>

                        <b-form-group
                            id="input-group-3"
                            label="Toplayıcı:"
                            label-for="input-3">
                            <b-form-select id="input-3" required v-model="form.atik_alim_user" :options="users"></b-form-select>
                        </b-form-group>

                        <b-form-group
                            id="input-group-4"
                            label="Resim Yükle"
                            label-for="input-4">
                            <b-form-file
                                v-model="file1"
                                :state="Boolean(file1)"
                                placeholder="Cihzınızdan resim seçin..."
                                drop-placeholder="Buraya sürükleyin..."
                                @change="previewFiles"
                                ref="file"
                                ></b-form-file>
                            </b-form-group>

                        <b-button block type="submit" variant="primary" class="float-right mb-5">Ekle</b-button>
                    </b-form>
                </b-col>    
            </b-row>
        </b-container>
    </div>
</template>

<script>

export default{
    data(){
        return {
            form: [],
            file1: null,
            file: ''
        }
    },

    methods: {
        ekle(){
            this.form.atik_alim_user = this.user.user_id;
            console.log(this.form);
            
            var form = new FormData();
            form.append("image", this.file);
            form.append("user", this.user.user_id);
            form.append("konum", this.location.latitude + "," + this.location.longitude);
            form.append("konteyner", this.form.atik_alim_konteyner);
            form.append("miktar", this.form.atik_alim_miktar);
            this.$store.dispatch("atikAlimEkle", form,  {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then(r => {
                this.$store.commit("initDurum", {
                    status: r.status,
                    message: r.message
                });

                if(r.status){
                    this.file = '';
                    this.form = [];
                    this.file1 = null;
                }
            });

        },

        previewFiles(event) {
            this.file = event.target.files[0];
        }
    },

    computed: {
        user(){
            return this.$store.state.user;
        },
        users(){
            return this.$store.state.users.map(c => {
                var data = null
                if(c.user_rutbe == 3){
                    data = {
                        value: c.user_id,
                        text: c.user_adsoyad,
                    }
                }
                return data;
            });
        },
        location(){
            return this.$store.state.konum;
        }
    }
}
</script>
